import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography, Tooltip } from "@mui/material";
import Header from "../../Controller/HeaderController/Header";
import BinCategory from "../../Constants/BinLegend";
import ListItem from "@mui/material/ListItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ViewBinInMap from "./ViewBinInMap";
import { MultiSelect } from "react-multi-select-component";
import ProjectList from "../../Constants/projectList";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import DataServices from "../../Services/DataServices";

const BinComponent = () => {
  const [selected, setSelected] = useState([
    {
      label: "Standard Grey Non-Ashtray Bin(Old Design)",
      value: "Standard Grey Non-Ashtray Bin (Old Design)",
    },
    {
      label: "Standard Grey Ashtray Bin(Old Design)",
      value: "Standard Grey Ashtray Bin (Old Design)",
    },
    {
      label: "Standard Grey Non-Ashtray Bin(Ausko)",
      value: "Standard Grey Non-Ashtray Bin (Ausko)",
    },
    {
      label: "Standard Grey Ashtray Bin(Ausko)",
      value: "Standard Grey Ashtray Bin (Ausko)",
    },
    {
      label: "Standard Green Non-Ashtray Bin",
      value: "Standard Green Non-Ashtray Bin",
    },
    {
      label: "Standard Green Ashtray Bin",
      value: "Standard Green Ashtray Bin",
    },
    {
      label: "STB bin (Orchard)",
      value: "STB bin (Orchard)",
    },
    {
      label: "Recycle Blue Bin",
      value: "Recycle Blue Bin",
    },
    {
      label: "Big Belly Recycle Bin",
      value: "Big Belly Recycle Bin",
    },
    {
      label: "Npark Metal Bin",
      value: "Npark Metal Bin",
    },
    {
      label: "Grey Monkey Bin(Old Design)",
      value: "Grey Monkey Bin (Old Design)",
    },
    {
      label: "Steel Cigarette Bin",
      value: "Steel Cigarette Bin",
    },
    {
      label: "Npark Green Bin",
      value: "Npark Green Bin",
    },
    {
      label: "Green Monkey Bin(Old Design)",
      value: "Green Monkey Bin (Old Design)",
    },
    {
      label: "New Monkey Bin(Ausko)",
      value: "New Monkey Bin (Ausko)",
    },
  ]);
  const [selectedProject, setSelectedProject] = useState(ProjectList);

  const [bintypes, setBinTypes] = useState(BinCategory);

  const getProjectDetails = useCallback((e) => {
    setBinTypes([...e]);
  });

  const handleChange = (selectedValue) => {
    /* if (selectedValue.length == 1 || selectedValue.length > 1) {
          let arr = selectedValue.reverse();
          setSelectedProject(arr[0]);
        } else {
            setSelectedProject(null);
        } */
  };

  const getProjectDropDown = () => {
    return (
      <div>
        <MultiSelect
          options={ProjectList}
          //value={selectedProject ? [selectedProject] : []}
          //onChange={(e) => handleChange(e)}
          value={selectedProject}
          onChange={setSelectedProject}
          labelledBy="Select"
          disableSearch // Disable search for simplicity (optional)
          //hasSelectAll={false}
          overrideStrings={{ selectSomeItems: "Select Project" }} // Set the placeholder text
        />
      </div>
    );
  };

  const getBinDropDown = () => {
    return (
      <div>
        <MultiSelect
          options={BinCategory}
          value={selected}
          onChange={setSelected}
          labelledBy="Select"
          hasSelectAll={true} // Disable select all option
          overrideStrings={{ selectSomeItems: "Select Bin Type" }} // Set the placeholder text
        />
      </div>
    );
  };

  return (
    <Grid container spacing={1} style={{ marginTop: `-20px` }}>
      <Grid item xs={12} md={12} sm={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8} sm={8}>
            <Header title={`Litter Bins`} />
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} sm={6}>
                {getProjectDropDown()}
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                {getBinDropDown()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sm={12}>
        <ViewBinInMap
          binType={selected}
          project={selectedProject}
          setProjectDetails={(e) => getProjectDetails(e)}
        />
      </Grid>
      <Grid item xs={12} md={12} sm={12} style={{ padding: `10px` }}>
        <Grid container spacing={2}>
          {bintypes.map((item, index) => (
            <Grid item xs={1.5} md={1.5} sm={1.5}>
              <Tooltip title={item.category} placement="top">
                <ListItem key={index}>
                  <Badge
                    badgeContent={item.count}
                    color="primary"
                    showZero
                    max={4000}>
                    <FiberManualRecordIcon style={{ color: item.color }} />
                  </Badge>
                  {/* <FiberManualRecordIcon style={{ color: item.color }} /> */}
                  <Typography
                    variant="body2"
                    style={{ fontSize: "10px", marginLeft: "20px" }}>
                    {item.category}
                  </Typography>
                </ListItem>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BinComponent;
