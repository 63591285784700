import React,{useState} from "react";
import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../Routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarCollapse";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
//import Logo from '../../Assets/ctm-01.png'
import Logo from '../../Assets/alai-logo-white-rounded-A.png'
import FullLogo from '../../Assets/ALAI_logo.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ListItemButton, ListItemIcon } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import AuthService from '../../Services/auth';
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

const Sidebar = (props) => {
  const navigate = useNavigate();
    const logout = () => {
      AuthService.logout();
      localStorage.removeItem("user")
      navigate('/signin')
    }
   
    return (
      <Drawer
        variant="permanent"
        sx={{
          width: props.open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: props.open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color
          },
        }}
      >
        <List
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          margin: 0,
          padding: 0,
          listStyle: "none",
          height: "100%",
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
          }
        }}>
            <Toolbar sx={{ marginBottom: "20px" }}>
            {props && props.open &&(
                  <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="center"
                  >
                  <IconButton sx={{color: colorConfigs.sidebar.color}} onClick={props.changeEvent}>
                   {/*  <KeyboardArrowRightIcon  style={{marginRight: `-200px`}}/>  */}
                   <img src={FullLogo} width={90} height={30}/>
                 {/*  <MenuIcon /> */}
                  </IconButton>
                  </Stack>
            )}
            {props && !props.open &&(
              <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="center"
                  >
                  <IconButton sx={{color: colorConfigs.sidebar.color}} onClick={props.changeEvent}>
                    <img src={Logo} width={40} height={40}/>
                  </IconButton>
                </Stack>
            )}           
        </Toolbar>
          {appRoutes.map((route, index) => (
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          ))}
          {/* <ListItemButton
            sx={{
              "&: hover": {
                backgroundColor: colorConfigs.sidebar.hoverBg
              },
            }}
            onClick={logout}
          >
            <Tooltip title={`Logout`}>
              <ListItemIcon sx={{
                color: colorConfigs.sidebar.color,
                margin: `-5px`,
              }}>
              <LogoutIcon />
              </ListItemIcon>
            </Tooltip>
              {`Logout`}
          </ListItemButton> */}
        </List>
      </Drawer>
    );
  };
  
  export default Sidebar;